import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useBoolean } from '@shared/lib/hooks';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styles from '../styles.module.scss';
import { ExportICDialog } from '../ExportICDialog';
import { useSummaryStore } from '@entities/summary';
export function ExportICButton() {
    const { t } = useTranslation();
    const [isExportICDialogOpen, , showExportICDialog, hideExportICDialog] = useBoolean(false);
    const { selectedRows } = useSummaryStore();
    return (_jsxs(_Fragment, { children: [_jsx(Button, { variant: "outlined", onClick: showExportICDialog, className: styles.button, disabled: !selectedRows.length, children: t('Export') }), isExportICDialogOpen && _jsx(ExportICDialog, { onClose: hideExportICDialog })] }));
}
