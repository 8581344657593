import { intlFormat } from 'date-fns';
export const getFormattedDateValue = (value) => {
    if (!value) {
        return '';
    }
    return intlFormat(value + 'T00:00:00.000', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });
};
