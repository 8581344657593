import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import { Toolbar } from './Toolbar';
import { useSummaryStore, getGroupedResultDataByIC, useSummaryTableGrouping, GroupingRowType, resultFields } from '@entities/summary';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { MRTTable } from '@shared/ui/MRTTable';
import { EditNote } from '@shared/ui/icons';
import { ResultFieldProperties } from '@shared/types/ResultFieldProperties';
import { useCurrentProject } from '@entities/project';
import { TableCell } from './TableCell';
import { ResultTableFilters } from './ResultTableFilters/ResultTableFilters';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@app/constants';
import { Checkbox, IconButton } from '@mui/material';
import classNames from 'classnames';
import { getFieldValue } from '../utils/getFieldValue';
import { areResultValueMatch } from '../utils/areResultValueMatch';
import { isFieldTypeTagOrDelivery } from '@shared/utils/isFieldTypeTagOrDelivery';
import { filterICs } from '../utils/filterICs';
export function ProjectSummaryTable({ data, isArchived }) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { groupingState, changeGroupingState } = useSummaryTableGrouping();
    const { reset, selectedRows, setSelectedRows } = useSummaryStore();
    const { project } = useCurrentProject();
    const groupedData = useSummaryStore(getGroupedResultDataByIC);
    const itemData = useMemo(() => {
        if (groupingState === GroupingRowType.grouped) {
            return [
                ...Object.values(groupedData.grouped)
                    .map(group => group.map((item, index) => index === group.length - 1 ? Object.assign(Object.assign({}, item), { isLastItem: true }) : item))
                    .flat(),
                ...Object.values(groupedData.unassigned)
            ];
        }
        return data;
    }, [groupingState, groupedData.grouped, groupedData.unassigned]);
    const handleEditPage = useCallback((documentId, pageId) => {
        if (documentId) {
            navigate(ROUTES.DOCUMENT_REVIEW.replace(':projectId', project.id)
                .replace(':docId', documentId)
                .replace(':pageId', (pageId - 1).toString()), { state: { previousLocationPathname: location.pathname } });
            reset();
        }
    }, []);
    const [isICChecked, setICChecked] = useState(false);
    const handleRowSelection = useCallback((row) => {
        let updatedRows = [];
        if (selectedRows.find(sItem => sItem.index === row.index)) {
            updatedRows = selectedRows.filter(sItem => sItem.index !== row.index);
        }
        else {
            updatedRows = [...selectedRows, row];
        }
        setSelectedRows(updatedRows);
    }, [selectedRows]);
    const filteredIcRows = filterICs(itemData);
    const handleSelectAllRows = () => {
        setSelectedRows(filteredIcRows);
    };
    const handleCheckBoxChange = () => {
        if (!selectedRows.length) {
            return handleSelectAllRows();
        }
        return setSelectedRows([]);
    };
    const handleRowsDeselection = () => {
        setSelectedRows([]);
    };
    const columns = useMemo(() => makeColumns(t, handleEditPage, handleRowSelection, selectedRows, filteredIcRows, handleCheckBoxChange, isArchived), [
        t,
        handleEditPage,
        handleRowSelection,
        selectedRows,
        filteredIcRows,
        handleCheckBoxChange,
        isArchived
    ]);
    return (_jsxs(_Fragment, { children: [!isArchived && _jsx(Toolbar, {}), (itemData === null || itemData === void 0 ? void 0 : itemData.length) > 0 && (_jsx("div", { children: _jsx(MRTTable, { rows: isICChecked ? filteredIcRows : itemData, columns: columns, filterComponent: ResultTableFilters, enablePagination: true, enableBottomToolbar: true, initialState: { pagination: { pageSize: 500, pageIndex: 0 } }, muiPaginationProps: {
                        rowsPerPageOptions: [50, 100, 500, 1000]
                    }, cellClassName: styles.cell, filterProps: {
                        groupingStatus: groupingState,
                        handleChangeGroupingStatus: changeGroupingState,
                        isChecked: isICChecked,
                        handleToggle: (value) => setICChecked(value),
                        handleRowsDeselection
                    }, muiTableContainerProps: {
                        sx: {
                            maxWidth: 'unset',
                            maxHeight: 'unset',
                            width: 'auto',
                            overflow: 'unset'
                        }
                    }, muiTablePaperProps: {
                        sx: {
                            overflow: 'unset',
                            maxWidth: 'unset',
                            maxHeight: 'unset',
                            width: 'fit-content'
                        }
                    } }) }))] }));
}
function makeColumns(t, handleEditPage, handleRowSelection, selectedRows, filteredIcRows, handleCheckBoxChange, isArchived) {
    const allICRowsSelected = selectedRows.length >= filteredIcRows.length;
    const configuration = [
        {
            id: 'selection',
            header: 'selection',
            muiTableHeadCellProps: {
                className: styles.tableSelectionCell
            },
            Header: () => Boolean(selectedRows.length) && (_jsx(Checkbox, { className: styles.headCellCheckbox, checked: filteredIcRows.length ? allICRowsSelected : false, onChange: handleCheckBoxChange, indeterminate: !allICRowsSelected && selectedRows.length > 0 && filteredIcRows.length > 0, disabled: filteredIcRows.length <= 0 })),
            Cell: ({ row }) => (_jsx("div", { className: row.original.isLastItem ? styles.lastItem : '', children: _jsx(Checkbox, { checked: selectedRows.findIndex(fItem => fItem.index === row.original.index) !== -1, onChange: () => handleRowSelection(row.original), disabled: isFieldTypeTagOrDelivery(row.original.type), className: styles.checkbox }) })),
            size: 50
        },
        ...resultFields.map((property, fieldIndex) => ({
            size: property.columnWidth,
            accessorFn: (row) => row[property.property === (ResultFieldProperties === null || ResultFieldProperties === void 0 ? void 0 : ResultFieldProperties.filename) ? 'documentName' : property.property],
            id: property.property,
            header: t(property.label),
            Cell: ({ row }) => {
                const cellValue = getFieldValue(row.original, property, row.original.index, t);
                return (_jsx("div", { className: classNames(styles.tableCellWrapper, row.original.isLastItem ? styles.lastItem : ''), children: _jsx(TableCell, { index: row.original.index, property: property.property, value: cellValue }, `result-table-body-cell-${fieldIndex}`) }));
            },
            enableSorting: property.sort || false,
            filterFn: (row, id, filterValue) => {
                const cellValue = row.getValue(id);
                return areResultValueMatch(cellValue, filterValue, property.format);
            }
        })),
        {
            accessorKey: 'Action',
            header: t('Action'),
            size: 100,
            Cell: ({ row }) => {
                return (_jsx("div", { className: classNames(styles.resultTableCell, row.original.isLastItem ? styles.lastItem : '', styles.tableCellWrapper), children: _jsx(IconButton, { onClick: () => {
                            handleEditPage(row.original.documentId, row.original.pageNumber);
                        }, children: _jsx(EditNote, { fontSize: 'large' }) }) }));
            }
        }
    ];
    if (isArchived) {
        configuration.shift();
    }
    return configuration;
}
